import { DialogContent, DialogTitle, Stack, Typography, type TypographyProps } from '@mui/material';
import { Grade, RegisterRequest } from '@spec/Talent';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationError } from '../../Errors';
import { getTalentUrl } from '../../Routes';
import { findTeam } from '../../domains/Organization';
import { Discipline } from '../../lib/Form';
import { useGrades } from '../../queries/talent';
import { useGateway } from '../../stores/Gateway';
import { useTeamsContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { DialogActionButtons } from '../StableDialog';
import { WaitQuery } from '../WaitLoading';
import { NOT_SELECTED_WORKPLACE } from '../WorkplaceSelector';
import {
    useDialogContext,
    useRegisterFormContext,
    type RegisterElements,
    type RegisterForm,
} from './Context';

const guard = (v: RegisterElements): v is Discipline<RegisterElements, 'teamId' | 'joinedAt'> =>
    v.teamId !== null && v.joinedAt !== null;

const toRegisterRequest = (form: RegisterForm, grades: Map<string, Grade>): RegisterRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw new ApplicationError('lack parameter');
    }
    const { gradeText, ...rest } = args;
    return {
        ...rest,
        grade: grades.get(form.gradeText.value) ?? null,
        workplace: form.workplace.value === NOT_SELECTED_WORKPLACE ? null : form.workplace.value,
    };
};

export const ConfirmDialog: React.FC<React.PropsWithChildren<unknown>> = () => {
    const navigate = useNavigate();
    const gateway = useGateway();
    const { closeDialog } = useDialogContext();
    const { form } = useRegisterFormContext();
    const maybeGrades = useGrades();
    const { teams } = useTeamsContext();
    if (form.teamId.value === null) {
        return null;
    }
    const team = findTeam(form.teamId.value, teams);
    return (
        <>
            <DialogTitle>以下の内容でアカウント登録しますか？</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mx={4} my={1}>
                    <FlexBox>
                        <Label>メールアドレス</Label>
                        <Typography>{form.email.value}</Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>社員番号</Label>
                        <Typography>{form.employeeCode.value}</Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>入社日</Label>
                        <Typography>{dayjs(form.joinedAt.value).format('YYYY-MM-DD')}</Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>新卒 or 中途</Label>
                        <Typography>
                            {form.isNewGraduate.value ? '新卒入社' : '中途入社'}
                        </Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>雇用形態</Label>
                        <Typography>{form.employmentStatus.value}</Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>グレード</Label>
                        <Typography>{form.gradeText.value}</Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>所属</Label>
                        <Typography>{team.name}</Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>役職</Label>
                        <Typography>{form.position.value}</Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>勤務地</Label>
                        <Typography>{form.workplace.value}</Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>氏名</Label>
                        <Typography>
                            {form.lastName.value} {form.firstName.value}
                        </Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>よみがな</Label>
                        <Typography>
                            {form.lastNameKana.value} {form.firstNameKana.value}
                        </Typography>
                    </FlexBox>
                    <FlexBox>
                        <Label>英語表記</Label>
                        <Typography>{form.romanName.value}</Typography>
                    </FlexBox>
                </Stack>
            </DialogContent>
            <WaitQuery size="medium" query={maybeGrades}>
                {({ data }) => (
                    <DialogActionButtons
                        form={form}
                        submitLabel="この内容で登録する"
                        cancelLabel="入力画面に戻る"
                        errorMessage="登録に失敗しました"
                        onSubmit={() =>
                            gateway.register(toRegisterRequest(form, data)).then(() => {
                                navigate(getTalentUrl(form.employeeCode.value));
                            })
                        }
                        closeDialog={() => {
                            // quick hack to reset previous error
                            form.failed = false;
                            closeDialog();
                        }}
                    />
                )}
            </WaitQuery>
        </>
    );
};

const Label = (props: TypographyProps) => <Typography sx={{ width: '8rem' }} {...props} />;
