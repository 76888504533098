import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import { User } from 'firebase/auth';
import React, {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from 'react';
import { ColorMode } from 'src/colors';
import { isAvailableTeam } from '../domains/Organization';
import { isAvailableTalent } from '../domains/Talent';
import { loadStorage, storeStorage } from '../lib/Storage';
import { useTeams } from '../queries/organization';
import { useTalents } from '../queries/talent';
import { WaitQuery } from './WaitLoading';

export const CurrentTimeContext = createContext(
    {} as {
        currentTime: Date;
    }
);

export const useCurrentTimeContext = () => useContext(CurrentTimeContext);

const DialogContext = createContext({});

export const DialogContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const [dialogMode, setDialogMode] = useState<string | null>(null);
    const closeDialog = () => setDialogMode(null);
    return (
        <DialogContext.Provider value={{ dialogMode, setDialogMode, closeDialog }}>
            {props.children}
        </DialogContext.Provider>
    );
};

export const useGenericDialogContext = <T,>() =>
    useContext(DialogContext) as {
        dialogMode: T | null;
        setDialogMode: Dispatch<SetStateAction<T>>;
        closeDialog: () => void;
    };

const TalentsContext = createContext(
    {} as {
        talents: Talent[];
        availableTalents: Talent[];
    }
);
export const useTalentsContext = () => useContext(TalentsContext);
export const TalentsContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const maybeTalents = useTalents();
    return (
        <WaitQuery query={maybeTalents}>
            {({ data }) => (
                <TalentsContext.Provider
                    value={{
                        talents: data,
                        availableTalents: data.filter(isAvailableTalent),
                    }}
                >
                    {props.children}
                </TalentsContext.Provider>
            )}
        </WaitQuery>
    );
};

const TeamsContext = createContext(
    {} as {
        teams: Team[];
        availableTeams: Team[];
    }
);
export const useTeamsContext = () => useContext(TeamsContext);
export const TeamsContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const maybeTeams = useTeams();
    return (
        <WaitQuery query={maybeTeams}>
            {({ data }) => (
                <TeamsContext.Provider
                    value={{
                        teams: data,
                        availableTeams: data.filter(isAvailableTeam),
                    }}
                >
                    {props.children}
                </TeamsContext.Provider>
            )}
        </WaitQuery>
    );
};

const TenantContext = createContext({} as { tenant: string });
export const useTenantContext = () => useContext(TenantContext);
export const TenantContextProvider: React.FC<{ tenant: string; children: ReactNode }> = (props) => {
    return (
        <TenantContext.Provider value={{ tenant: props.tenant }}>
            {props.children}
        </TenantContext.Provider>
    );
};

const stringToColorMode = (v: string): ColorMode => (v === 'dark' ? 'dark' : 'light');
const COLOR_MODE_STORAGE_KEY = 'colorMode';

const ColorModeContext = createContext(
    {} as {
        colorMode: ColorMode;
        toggleColorMode: () => void;
    }
);
export const useColorModeContext = () => useContext(ColorModeContext);
export const ColorModeContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const [colorMode, setColorMode] = useState(() =>
        stringToColorMode(loadStorage(COLOR_MODE_STORAGE_KEY, 'light'))
    );
    const toggleColorMode = () => {
        setColorMode((prev) => {
            const next = prev === 'light' ? 'dark' : 'light';
            storeStorage(COLOR_MODE_STORAGE_KEY, next);
            return next;
        });
    };
    return (
        <ColorModeContext.Provider value={{ colorMode, toggleColorMode }}>
            {props.children}
        </ColorModeContext.Provider>
    );
};

const FirebaseUserContext = createContext({} as { firebaseUser: User });
export const useFirebaseUserContext = () => useContext(FirebaseUserContext);
export const FirebaseUserContextProvider: React.FC<{ firebaseUser: User; children: ReactNode }> = (
    props
) => {
    return (
        <FirebaseUserContext.Provider value={{ firebaseUser: props.firebaseUser }}>
            {props.children}
        </FirebaseUserContext.Provider>
    );
};

const AvatarUpdateContext = createContext(
    {} as {
        timestamp: number;
        refresh: () => void;
    }
);
export const useAvatarUpdateContext = () => useContext(AvatarUpdateContext);
export const AvatarUpdateContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const [timestamp, setTimestamp] = useState(new Date().getTime());
    const refresh = () => setTimestamp(new Date().getTime());
    return (
        <AvatarUpdateContext.Provider value={{ timestamp, refresh }}>
            {props.children}
        </AvatarUpdateContext.Provider>
    );
};
