import { env } from '@env';
import { ApiReference, ApiReferenceResponse } from '@spec/ApiReference';
import { Grant, GrantPrivilegeRequest, GrantsResponse } from '@spec/Grants';
import { Team, TeamsResponse } from '@spec/Organization';
import {
    DashboardResponse,
    Grade,
    GradesResponse,
    LeaveOfAbsence,
    LeaveOfAbsencesResponse,
    MeResponse,
    NextSabbaticalLeave,
    NextSabbaticalLeavesResponse,
    RegisterRequest,
    SabbaticalLeave,
    SabbaticalLeaveBaseDate,
    SabbaticalLeavesResponse,
    Talent,
    TalentId,
    TalentResponse,
    TalentsResponse,
} from '@spec/Talent';
import {
    CompanyValue,
    CompanyValuesResponse,
    CreateValueFeedbackResponse,
    EditValueFeedbackRequest,
    MemberFeedbacksResponse,
    SelectReviewerRequest,
    SelectionRequest,
    SelectionsResponse,
    TalentFeedbacksResponse,
    ValueFeedbackDetailResponse,
    ValueFeedbackScoresResponse,
    ValueFeedbackSelection,
    ValueFeedbackStats,
    ValueFeedbackStatsResponse,
    WriteFeedbackRequest,
    WriteSelfInsightRequest,
} from '@spec/ValueFeedback';
import { Workplace, WorkplacesResponse } from '@spec/Workplace';
import { QueryClient } from '@tanstack/react-query';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuth, signOut } from 'firebase/auth';
import { createContext, useContext, type ReactNode } from 'react';
import { firebaseApp } from '../App';
import { decrypt, generateIV } from '../Crypto';
import {
    ApplicationError,
    AuthError,
    ErrorWithCode,
    ForbiddenError,
    NotFoundError,
    ParameterError,
} from '../Errors';

// expect like "2021-01-14T09:20:00.000Z"
const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const dateKeys = new Set(['birthday', 'nextSabbaticalLeave', 'deadline', 'reminders']);

const isDateLikeString = (v: unknown) => typeof v === 'string' && datePattern.test(v);

export const parseJson = (json: string) =>
    JSON.parse(json, (k, v) => {
        if (/(At|On)$/.test(k) || dateKeys.has(k)) {
            if (isDateLikeString(v)) {
                return new Date(v);
            }
            if (Array.isArray(v)) {
                return v.map((e) => (isDateLikeString(e) ? new Date(e) : e));
            }
        }
        return v;
    });

export const blobToBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

class Driver {
    private uiRevision: string;
    private desiredRevision: string | null;
    public tenant: string | null;

    constructor(uiRevision: string) {
        this.uiRevision = uiRevision;
        this.desiredRevision = null;
        this.tenant = null;
    }

    shouldUpdateUi(): boolean {
        return Number(this.desiredRevision) > Number(this.uiRevision);
    }

    get<T>(url: string): Promise<T> {
        return this._request(url);
    }

    post<T>(url: string, body?: Record<string, any>): Promise<T> {
        return this._request(url, 'POST', body);
    }

    put<T>(url: string, body?: Record<string, any>): Promise<T> {
        return this._request(url, 'PUT', body);
    }

    delete<T>(url: string, body?: Record<string, any>): Promise<T> {
        return this._request(url, 'DELETE', body);
    }

    private setDesiredUiRevision(revision: string | null) {
        if (revision === null) {
            return;
        }
        this.desiredRevision = revision;
    }

    private async _request(url: string, method?: HttpMethod, body?: Record<string, any>) {
        const token = await this._getToken();
        const iv = generateIV();
        const headers = new Headers({
            Authorization: `Bearer ${token}`,
            'Hitonowa-Encryption-IV': iv,
        });
        if (this.tenant !== null) {
            headers.set('Hitonowa-Tenant', this.tenant);
        }
        const options: RequestInit = {
            mode: 'cors',
            method,
            headers,
        };
        if (body !== undefined) {
            options.body = JSON.stringify(body);
        }
        const res = await fetch(`${env.apiHost}${url}`, options);
        if (res.ok === false) {
            throw await this._parseError(res);
        }
        this.setDesiredUiRevision(res.headers.get('Hitonowa-Desired-UI-Revision'));
        return this._parseEncryptedResponse(iv, res);
    }

    private _getToken(): Promise<string> {
        const user = getAuth(firebaseApp).currentUser;
        if (user === null) {
            throw Error('Require sign-in');
        }
        return user.getIdToken();
    }

    private async _parseError(res: Response): Promise<Error> {
        try {
            const data = JSON.parse(await res.text());
            switch (res.status) {
                case 400:
                    return new ParameterError(data.message, data.code);
                case 401:
                    return new AuthError(data.message, data.code);
                case 403:
                    return new ForbiddenError(data.message);
                case 404:
                    return new NotFoundError(data.message, data.code);
                default:
                    return new ApplicationError(data.message);
            }
        } catch (e) {
            return new ApplicationError('Unexpected response');
        }
    }

    private async _parseEncryptedResponse(iv: string, res: Response) {
        const decrypted = await decrypt(iv, await res.text());
        return parseJson(decrypted);
    }
}

export class Gateway {
    private driver: Driver;

    signOutMessage: string | null;

    constructor(uiRevision: string) {
        this.driver = new Driver(uiRevision);
        this.signOutMessage = null;
    }

    setTenant(tenant: string) {
        this.driver.tenant = tenant;
    }

    private async handleRequest<T>(request: Promise<T>) {
        try {
            return await request;
        } catch (error) {
            if (error instanceof AuthError) {
                if (error.code === 'auth/token-has-expired') {
                    this.signOutMessage = '一定時間操作がなかったためログアウトされました';
                    await signOut(getAuth(firebaseApp));
                }
                if (error.code === 'auth/token-revoked') {
                    this.signOutMessage = '再ログインが必要です';
                    await signOut(getAuth(firebaseApp));
                }
            }
            throw error instanceof Error ? error : Error(`Unknown Error: ${JSON.stringify(error)}`);
        }
    }

    get<T>(url: string): Promise<T> {
        return this.handleRequest(this.driver.get(url));
    }

    post<T>(url: string, body?: Record<string, any>): Promise<T> {
        return this.handleRequest(this.driver.post(url, body));
    }

    put<T>(url: string, body?: Record<string, any>): Promise<T> {
        return this.handleRequest(this.driver.put(url, body));
    }

    delete<T>(url: string, body?: Record<string, any>): Promise<T> {
        return this.handleRequest(this.driver.delete(url, body));
    }

    shouldUpdateUi(): boolean {
        return this.driver.shouldUpdateUi();
    }

    me(): Promise<MeResponse> {
        return this.driver.get('/me');
    }

    signIn(): Promise<void> {
        logEvent(getAnalytics(firebaseApp), 'signIn');
        return this.driver.post('/signin');
    }

    async signOut(): Promise<void> {
        logEvent(getAnalytics(firebaseApp), 'signOut');
        const user = getAuth(firebaseApp).currentUser;
        if (user === null) {
            throw new ApplicationError('User does not signed-in');
        }
        this.signOutMessage = 'ログアウトしました';
        await this.driver.post('/signout');
        await signOut(getAuth(firebaseApp));
    }

    register(args: RegisterRequest): Promise<void> {
        return this.driver.post('/register', args);
    }

    signUp(
        lastName: string,
        firstName: string,
        lastNameKana: string,
        firstNameKana: string,
        romanName: string,
        slackName: string
    ): Promise<void> {
        return this.driver.post('/signup', {
            lastName,
            firstName,
            lastNameKana,
            firstNameKana,
            romanName,
            slackName,
        });
    }

    fetchAllTalents(): Promise<Talent[]> {
        return this.driver.get<TalentsResponse>('/talents').then((res) => res.talents);
    }

    fetchTalent(employeeCode: string): Promise<TalentResponse> {
        return this.driver.get<TalentResponse>(`/talents/${employeeCode}`);
    }

    fetchGrants(): Promise<Grant[]> {
        return this.driver.get<GrantsResponse>(`/grants`).then((res) => res.grants);
    }

    fetchTeams(): Promise<Team[]> {
        return this.driver.get<TeamsResponse>('/organization/teams').then((res) => res.teams);
    }

    grantPrivilege(args: GrantPrivilegeRequest): Promise<void> {
        return this.driver.post('/grants', args);
    }

    revokePrivilege(args: GrantPrivilegeRequest): Promise<void> {
        return this.driver.post('/grants/revoke', args);
    }

    getDashboard(): Promise<DashboardResponse> {
        return this.driver.get('/dashboard');
    }

    apiReference(): Promise<ApiReference[]> {
        return this.driver.get<ApiReferenceResponse>('/').then((res) => res.apis);
    }

    wakeUp(): Promise<void> {
        if (env.wakeUpRequest) {
            void fetch(`${env.apiHost}wakeup`);
        }
        return Promise.resolve();
    }

    fetchAllGrades(): Promise<Grade[]> {
        return this.driver.get<GradesResponse>('/grades').then((res) => res.grades);
    }

    fetchWorkplaces(): Promise<Workplace[]> {
        return this.driver.get<WorkplacesResponse>('/workplaces').then((res) => res.workplaces);
    }

    fetchCompanyValues(): Promise<CompanyValue[]> {
        return this.driver
            .get<CompanyValuesResponse>('/company-values')
            .then((res) => res.companyValues);
    }

    fetchValueFeedbackDetail(valueFeedbackId: number): Promise<ValueFeedbackDetailResponse> {
        return this.driver.get<ValueFeedbackDetailResponse>(`/value-feedbacks/${valueFeedbackId}`);
    }

    createValueFeedback(args: EditValueFeedbackRequest): Promise<number> {
        return this.driver
            .post<CreateValueFeedbackResponse>('/value-feedbacks', args)
            .then((res) => res.valueFeedbackId);
    }

    updateValueFeedback(ValueFeedbackId: number, args: EditValueFeedbackRequest): Promise<void> {
        return this.driver.put(`/value-feedbacks/${ValueFeedbackId}`, args);
    }

    deleteValueFeedback(ValueFeedbackId: number): Promise<void> {
        return this.driver.delete(`/value-feedbacks/${ValueFeedbackId}`);
    }

    publishValueFeedback(ValueFeedbackId: number): Promise<void> {
        return this.driver.post(`/value-feedbacks/${ValueFeedbackId}/publish`);
    }

    unpublishValueFeedback(ValueFeedbackId: number): Promise<void> {
        return this.driver.delete(`/value-feedbacks/${ValueFeedbackId}/publish`);
    }

    fetchValueFeedbackSelections(valueFeedbackId: number): Promise<ValueFeedbackSelection[]> {
        return this.driver
            .get<SelectionsResponse>(`/value-feedbacks/${valueFeedbackId}/selections`)
            .then((res) => res.selections);
    }

    reserveValueFeedbackSelection(valueFeedbackId: number, args: SelectionRequest): Promise<void> {
        return this.driver.post(`/value-feedbacks/${valueFeedbackId}/selections/reserve`, args);
    }

    cancelValueFeedbackReservedSelection(
        valueFeedbackId: number,
        talentId: TalentId
    ): Promise<void> {
        return this.driver.delete(
            `/value-feedbacks/${valueFeedbackId}/selections/reserve/${talentId}`
        );
    }

    selectValueFeedbackReviewer(
        valueFeedbackId: number,
        args: SelectReviewerRequest
    ): Promise<void> {
        return this.driver.post(`/value-feedbacks/${valueFeedbackId}/selections`, args);
    }

    cancelValueFeedbackReviewer(
        valueFeedbackId: number,
        reviewerTalentId: TalentId
    ): Promise<void> {
        return this.driver.delete(
            `/value-feedbacks/${valueFeedbackId}/selections/${reviewerTalentId}`
        );
    }

    writeFeedback(
        valueFeedbackId: number,
        talentId: TalentId,
        args: WriteFeedbackRequest
    ): Promise<void> {
        return this.driver.post(`/value-feedbacks/${valueFeedbackId}/feedback/${talentId}`, args);
    }

    writeSelfInsight(valueFeedbackId: number, args: WriteSelfInsightRequest): Promise<void> {
        return this.driver.post(`/value-feedbacks/${valueFeedbackId}/self-insight`, args);
    }

    getValueFeedbackStats(valueFeedbackId: number): Promise<ValueFeedbackStats> {
        return this.driver
            .get<ValueFeedbackStatsResponse>(`/value-feedbacks/${valueFeedbackId}/stats`)
            .then((res) => res.stats);
    }

    getTalentFeedbacks(
        valueFeedbackId: number,
        talentId: TalentId
    ): Promise<TalentFeedbacksResponse> {
        return this.driver.get<TalentFeedbacksResponse>(
            `/value-feedbacks/${valueFeedbackId}/talents/${talentId}`
        );
    }

    getMemberFeedbacks(
        valueFeedbackId: number,
        talentId: TalentId
    ): Promise<MemberFeedbacksResponse> {
        return this.driver.get<MemberFeedbacksResponse>(
            `/value-feedbacks/${valueFeedbackId}/members/${talentId}`
        );
    }

    getValueFeedbackScores(valueFeedbackId: number): Promise<ValueFeedbackScoresResponse> {
        return this.driver.get<ValueFeedbackScoresResponse>(
            `/value-feedbacks/${valueFeedbackId}/scores`
        );
    }

    getSabbaticalLeaves(): Promise<{
        leaves: SabbaticalLeave[];
        baseDates: SabbaticalLeaveBaseDate[];
    }> {
        return this.driver.get<SabbaticalLeavesResponse>('/sabbatical-leaves');
    }

    getNextSabbaticalLeaves(): Promise<NextSabbaticalLeave[]> {
        return this.driver
            .get<NextSabbaticalLeavesResponse>('/sabbatical-leaves/next')
            .then((res) => res.leaves);
    }

    getLeaveOfAbsences(): Promise<LeaveOfAbsence[]> {
        return this.driver
            .get<LeaveOfAbsencesResponse>('/leave-of-absences')
            .then((res) => res.leaves);
    }
}

const MAX_RETRIES = 3;
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: env.queryClientStaleSeconds * 1000,
            refetchOnWindowFocus: false,
            retry: (failureCount, error) => {
                if (error instanceof ErrorWithCode) {
                    return false;
                }
                return failureCount < MAX_RETRIES;
            },
        },
    },
});

const GatewayContext = createContext({} as { gateway: Gateway });
export const useGateway = () => useContext(GatewayContext).gateway;
export const GatewayContextProvider = (props: { gateway: Gateway; children: ReactNode }) => (
    <GatewayContext.Provider value={{ gateway: props.gateway }}>
        {props.children}
    </GatewayContext.Provider>
);
